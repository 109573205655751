import { Construction as ConstructionIcon } from '@mui/icons-material';
import type { FC } from 'react';

import './NotSupported.scss';
import LifestackLogoName from '../../../assets/logo/logo_with_name.png';
import ScreenNotSupported from '../../../assets/common/screen_not_supported.png';

const NotSupported: FC = () => {
  return (
    <div className="wrapper">
      <div className="not-supported">
        <img src={LifestackLogoName} alt="Lifestack Logo" className="not-supported__logo" />
        <div className="not-supported__content">
          <img
            src={ScreenNotSupported}
            alt="Screen Not Supported"
            className="not-supported__content__image"
          />
          <div className="not-supported__content__title">Screen size not supported yet 😵</div>
          <div className="not-supported__content__desc">
            We are sorry but this screen size is not supported. Please switch to a screen wider than
            992px or use the mobile app.
          </div>
        </div>
        <div className="not-supported__footer">
          <a href={'https://lifestack.ai'} target="__blank">
            Website
          </a>
          <a href="mailto:contact@demind-inc.com" target="__blank">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotSupported;
