import { ReactNode, useState, createContext, useContext, useEffect } from 'react';
import { User } from '@demind-inc/core';
import { useGoogleLogin } from '@react-oauth/google';
import * as Sentry from '@sentry/react';

import { useSignIn, useUpdateUser } from '../mutations';
import { Status } from '../types';
import {
  accountApi,
  exchangeCodeToToken,
  fetchGoogleUser,
  getOAuthAccessToken,
  getOAuthRefreshToken,
  setOAuthAccessToken,
  setOAuthRefreshToken,
} from '../api';
import { useUser } from '../queries';
import { transformExpiryDate } from '../helpers';
import { trackEventGA4 } from '../../utils';
import Cookies from 'js-cookie';

interface IAuthContext {
  status: Status;
  user: User;
  isUserFetching: boolean;
  isUpdatingUser?: boolean;
  signIn: () => void;
  signOut: () => void;
  updateUser: (user: Partial<User>) => Promise<void>;
}

// Context
export const AuthContext = createContext({} as IAuthContext);
export const useAuthContext = () => useContext(AuthContext);
const infiniteDays = 365 * 100;

export const gCalScopes =
  'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly';

// Provider
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [status, setStatus] = useState<Status>(Status.LOADING);
  const [userId, setUserId] = useState<string>('');
  const { user, isFetching: isUserFetching } = useUser({ userId });
  const { updateUser: updateUserMutation, isPending: isUpdatingUser } = useUpdateUser();

  const { signIn: signInMutation } = useSignIn();

  useEffect(() => {
    const authenticate = async () => {
      setStatus(Status.LOADING);
      const localUserId = localStorage.getItem('lifestack_user_id');
      const localRefresh = getOAuthRefreshToken();
      const localAccess = getOAuthAccessToken();
      const jwtConfigured = localStorage.getItem('jwt_configured');

      if (localUserId && localRefresh && localAccess) {
        // Introduce the JWT flow if it's not configured
        //TODO: Remove this after all user migrates to JWT flow
        if (!jwtConfigured || jwtConfigured === 'false') {
          const { accessToken, refreshToken } = (await accountApi.generateJwt(localUserId)).data;
          setOAuthRefreshToken(refreshToken);
          setOAuthAccessToken(accessToken);
          localStorage.setItem('jwt_configured', 'true');
        }

        setUserId(localUserId);
        setStatus(Status.AUTHORIZED);

        // Store them in cookies to share the login-state with the chrome extension
        Cookies.set('lifestack_user_id', localUserId!, { expires: infiniteDays });
        Cookies.set('lifestack_refresh_token', localRefresh!, { expires: infiniteDays });
        Cookies.set('lifestack_access_token', localAccess!, { expires: 30 });
        return;
      }

      setStatus(Status.UN_AUTHORIZED);
    };

    authenticate();
  }, []);

  // Set up Sentry user
  useEffect(() => {
    if (!user.userId) {
      return;
    }
    Sentry.setUser({ id: user.userId, email: user.email || '' });
  }, [user]);

  const updateUser = async (newData: Partial<User>) => {
    await updateUserMutation({ userId: user.userId, newUserInfo: newData });
  };

  const signIn = useGoogleLogin({
    flow: 'auth-code',
    redirect_uri: 'postmessage',
    onSuccess: async (codeResponse) => {
      setStatus(Status.LOADING);
      trackEventGA4('Button_click', 'sign_in_with_google');

      try {
        const cred = await exchangeCodeToToken({
          serverAuthCode: codeResponse.code,
        });
        if (!cred) {
          setStatus(Status.UN_AUTHORIZED);
          return;
        }
        const googleAccessToken = cred.access_token!;
        const googleRefreshToken = cred.refresh_token!;
        const googleTokenExpiryDate = cred.expires_in ? transformExpiryDate(cred.expires_in) : '';

        const googleUser = await fetchGoogleUser({ accessToken: cred.access_token! });
        const {
          user: dbUser,
          refreshToken,
          accessToken,
        } = await signInMutation({
          userId: googleUser.sub!,
          accessToken: googleAccessToken,
          refreshToken: googleRefreshToken,
          tokenExpiryDate: googleTokenExpiryDate,
          newUserInfo: {
            email: googleUser.email,
          },
        });
        setUserId(dbUser.userId);
        localStorage.setItem('lifestack_access_token', accessToken);
        localStorage.setItem('lifestack_refresh_token', refreshToken);
        localStorage.setItem('lifestack_user_id', dbUser.userId);
        localStorage.setItem('jwt_configured', 'true');

        // Store them in cookies to share the login-state with the chrome extension
        Cookies.set('lifestack_user_id', dbUser.userId!, { expires: infiniteDays });
        Cookies.set('lifestack_refresh_token', refreshToken!, { expires: infiniteDays });
        Cookies.set('lifestack_access_token', accessToken!, { expires: 30 });
        setStatus(Status.AUTHORIZED);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
        setStatus(Status.UN_AUTHORIZED);
      }
    },
    onError: (error) => {
      console.error(error);
      setStatus(Status.UN_AUTHORIZED);
    },
    scope: gCalScopes,
  });

  const signOut = () => {
    localStorage.setItem('lifestack_user_id', '');
    localStorage.setItem('lifestack_refresh_token', '');
    localStorage.setItem('lifestack_access_token', '');
    Cookies.remove('lifestack_user_id');
    Cookies.remove('lifestack_refresh_token');
    Cookies.remove('lifestack_access_token');
  };

  return (
    <AuthContext.Provider
      value={{ status, isUpdatingUser, user, isUserFetching, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};
