import { CircadianPhase, TaskItem } from '@demind-inc/core';
import { FC, useMemo } from 'react';
import { useDrag } from 'react-dnd';
import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import dayjs from 'dayjs';

import './KanbanTaskCard.scss';
import { getCssVariable } from '../../../utils';
import { circadianPhaseInfo } from '../../../data-access';
import clsx from 'clsx';
import { useGeneralSettings } from '../../../hooks/useGeneralSettings';
import { formatTaskTimeRange, getFormattedOverdueTime } from '../../../helpers';

interface KanbanTaskCardProps {
  task: TaskItem;
  phase?: CircadianPhase;
  projectName?: string;
  isOverDue?: boolean;
  onClick?: () => void;
}

const labelCallbackColor = getCssVariable('--color-gray-light');
const taskIncompleteColor = getCssVariable('--color-gray-dark');
const taskCompleteColor = getCssVariable('--color-green-base');

const KanbanTaskCard: FC<KanbanTaskCardProps> = ({
  task,
  projectName,
  phase,
  isOverDue = false,
  onClick = () => void 0,
}) => {
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'TASK',
    item: task,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const taskTime = formatTaskTimeRange({
    startDatetime: task.startDateTime?.datetime ? dayjs(task.startDateTime.datetime) : undefined,
    endDatetime: task.dueDateTime?.datetime ? dayjs(task.dueDateTime.datetime) : undefined,
    timeFormat,
  });

  const taskPhaseInfo = useMemo(() => (phase ? circadianPhaseInfo[phase] : undefined), [phase]);

  return (
    <div
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="kanban-task-card"
      onClick={onClick}
      key={task.taskId}
    >
      <div className="kanban-task-card__top-group">
        {isOverDue ? (
          <div className="kanban-task-card__top-group__overdue">
            {getFormattedOverdueTime(task, timeFormat)}
          </div>
        ) : (
          <div className="kanban-task-card__top-group__time">
            {taskTime ? (
              <div
                className="kanban-task-card__time"
                style={{ borderColor: taskPhaseInfo?.color, color: taskPhaseInfo?.color }}
              >
                {taskTime}
              </div>
            ) : (
              <div className="kanban-task-card__time">Drag to set time</div>
            )}
            {taskPhaseInfo?.label && (
              <div
                className="kanban-task-card__phase"
                style={{ backgroundColor: taskPhaseInfo?.color }}
              >
                {taskPhaseInfo.label}
              </div>
            )}
          </div>
        )}
        <div className="kanban-task-card__project"># {projectName}</div>
      </div>
      <div className="kanban-task-card__bottom-group">
        {task.completed ? (
          <CheckCircle
            fontSize="small"
            style={{ color: taskCompleteColor }}
            className="kanban-task-card__bottom-group__icon"
          />
        ) : (
          <CheckCircleOutline
            fontSize="small"
            style={{ color: taskIncompleteColor }}
            className="kanban-task-card__bottom-group__icon"
          />
        )}
        <div>
          <span
            className={clsx('kanban-task-card__title', {
              'kanban-task-card__title--done': task.completed,
            })}
          >
            {task.name}
          </span>
          <div className="kanban-task-card__labels">
            {task.labels?.map((label) => (
              <div
                className="kanban-task-card__labels__item"
                id={label.name}
                style={{ backgroundColor: label.color || labelCallbackColor }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KanbanTaskCard;
