import { useMutation, useQueryClient } from '@tanstack/react-query';
import { calendarApi } from '../api';
import { CalendarEventModifyOption } from '../types';
import { CalendarEvent } from '@demind-inc/core';
import { merge, cloneDeep } from 'lodash';

export interface UseUpdateCalendarEventParams {
  calendarId: string;
  eventId: string;
  userId: string;
  newEventOption: CalendarEventModifyOption;
}

export function useUpdateCalendarEvent() {
  const queryClient = useQueryClient();

  const updateCalendarEventMutation = useMutation({
    mutationFn: ({ calendarId, eventId, userId, newEventOption }: UseUpdateCalendarEventParams) => {
      return calendarApi.updateCalendarEvent(userId, calendarId, eventId, newEventOption);
    },
    onMutate: async ({ eventId: targetEventId, newEventOption }: UseUpdateCalendarEventParams) => {
      await queryClient.cancelQueries({ queryKey: ['lifestack.calendar.events'] });
      queryClient.setQueriesData<CalendarEvent[]>(
        { queryKey: ['lifestack.calendar.events'] },
        (prevEvents) => {
          const newEvents = cloneDeep(prevEvents); // By cloning deeply, set a new reference to re-render the component.
          return newEvents?.map((event) =>
            event.eventId === targetEventId
              ? {
                  ...merge({}, event, newEventOption),
                  ...(newEventOption.categories ? { categories: newEventOption.categories } : {}),
                }
              : event
          );
        }
      );
      if (newEventOption.isAllDay) {
        await queryClient.cancelQueries({ queryKey: ['lifestack.calendar.allDayEvents'] });
        queryClient.setQueriesData<CalendarEvent[]>(
          { queryKey: ['lifestack.calendar.allDayEvents'] },
          (prevEvents) => {
            const newEvents = cloneDeep(prevEvents); // By cloning deeply, set a new reference to re-render the component.
            return newEvents?.map((event) =>
              event.eventId === targetEventId ? merge({}, event, newEventOption) : event
            );
          }
        );
      }
    },
    onSuccess: (_, { newEventOption }) => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.events'] });

      if (newEventOption.eventMetrics || newEventOption.categories?.length) {
        queryClient.invalidateQueries({ queryKey: ['lifestack.circadian'] });
      }
      if (newEventOption.isAllDay) {
        queryClient.invalidateQueries({ queryKey: ['lifestack.calendar.allDayEvents'] });
      }
    },
  });

  return {
    updateCalendarEvent: updateCalendarEventMutation.mutateAsync,
    ...updateCalendarEventMutation,
  };
}
