import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeApp } from 'firebase/app';
import '@fontsource/roboto';
import '@fontsource-variable/nunito-sans';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { RecoilRoot } from 'recoil';
import { QueryClient } from '@tanstack/react-query';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { ThemeProvider } from '@mui/material';

import { AuthProvider, SubscriptionProvider } from './data-access';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { AppRouter } from './routing';
import theme from './theme';

const MAX_AGE_OF_QUERY_CACHE = 1000 * 60 * 60 * 24 * 7; // 1 week
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: MAX_AGE_OF_QUERY_CACHE, // cache time till it's discarded,
    },
  },
});
const persister = createAsyncStoragePersister({
  storage: window.localStorage,
  throttleTime: 3000,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
        <RecoilRoot>
          <AuthProvider>
            <SubscriptionProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ThemeProvider theme={theme}>
                  <AppRouter />
                </ThemeProvider>
              </LocalizationProvider>
            </SubscriptionProvider>
          </AuthProvider>
        </RecoilRoot>
      </GoogleOAuthProvider>
    </PersistQueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
