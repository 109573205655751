import { useMutation, useQueryClient } from '@tanstack/react-query';
import { tasksApi } from '../api';

interface UseDeleteTaskProps {
  taskId: string;
  boardId: string;
  userId: string;
}

export function useDeleteTask() {
  const queryClient = useQueryClient();

  const deleteTaskMutation = useMutation({
    mutationFn: ({ taskId, boardId, userId }: UseDeleteTaskProps) => {
      return tasksApi.deleteTodoTask(taskId, boardId, userId);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
    },
  });

  return {
    deleteTask: deleteTaskMutation.mutateAsync,
    isDeleting: deleteTaskMutation.isPending,
    ...deleteTaskMutation,
  };
}
