import { useMutation, useQueryClient } from '@tanstack/react-query';

import { tasksApi } from '../api';

interface UseUpdateTodoTaskParams {
  taskId: string;
  completed: boolean;
  boardId: string;
  userId: string;
}
export const useUpdateTaskStatus = () => {
  const queryClient = useQueryClient();

  const updateTaskMutation = useMutation({
    mutationFn: async ({ taskId, completed, boardId, userId }: UseUpdateTodoTaskParams) => {
      return tasksApi
        .updateCompleteStatusOfTask(taskId, boardId, userId, completed)
        .then(({ data }) => data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks'] });
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.overdueTasks'] });
    },
  });

  return {
    updateTodoTaskStatus: updateTaskMutation.mutateAsync,
    isTaskStatusUpdating: updateTaskMutation.isPending,
    ...updateTaskMutation,
  };
};
