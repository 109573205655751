import React from 'react';

import './PeakEnergy.scss';
import { ReactComponent as EnergyIcon } from '../../../assets/circadian/energy.svg';
import { HelpOutline } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CircadianTooltipContent } from '../CircadianTooltipContent';
import { CustomTooltip } from '../../common';
import { NoWearableChip } from '../NoWearableChip';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';

interface PeakEnergyProps {
  score: string;
  hasNoSleepData: boolean;
}

const PeakEnergy: React.FC<PeakEnergyProps> = ({ score, hasNoSleepData }) => {
  return (
    <div className="peak-energy">
      <EnergyIcon className="peak-energy__icon" />
      <div className="peak-energy__score">{score}</div>
      <CustomTooltip title={<CircadianTooltipContent />} className="peak-energy__tooltip">
        <HelpOutline fontSize="small" className="peak-energy__tooltip__icon" />
      </CustomTooltip>
      {hasNoSleepData && (
        <Tooltip
          classes={{
            tooltip: clsx('peak-energy__no-data__tooltip'),
          }}
          title={<NoWearableChip />}
        >
          <ErrorOutlineIcon fontSize="small" className="peak-energy__no-data__tooltip__icon" />
        </Tooltip>
      )}
    </div>
  );
};

export default PeakEnergy;
