import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { FC, lazy } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { CalendlySchedulePage, PaywallPage } from '../pages';
import App from '../App';
import { RequireAuth, LoginContainer } from '.';
import { CreateEventMenuProvider, EventDetailsMenuProvider } from '../data-access';
import { useBreakpoints } from '../utils';
import { NotSupported } from '../components';
import { CallbackRoutes } from './callback.routings';
import { BasisProvider } from './basisProvider';
import { OnboardingRoutes } from './onboarding.routings';
import { SettingsRoutes } from './settings.routings';

const LazyDashboard = lazy(() => import('../pages/DashboardPage/DashboardPage'));

export const AppRouter: FC = () => {
  const { isDesktop } = useBreakpoints();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route element={isDesktop ? <Outlet /> : <NotSupported />}>
            <Route index element={<LoginContainer />}></Route>
            <Route path={'subscribe'} element={<PaywallPage />} />
            <Route path="/*" element={<RequireAuth />}>
              <Route element={<BasisProvider />}>
                <Route
                  path="home"
                  element={
                    <CreateEventMenuProvider>
                      <EventDetailsMenuProvider>
                        <DndProvider backend={HTML5Backend}>
                          <LazyDashboard />
                        </DndProvider>
                      </EventDetailsMenuProvider>
                    </CreateEventMenuProvider>
                  }
                />
                <Route path="onboarding/*" element={<OnboardingRoutes />} />
                <Route path="settings/*" element={<SettingsRoutes />} />
              </Route>
              <Route path="callback/*" element={<CallbackRoutes />} />
            </Route>
          </Route>
          <Route path={'/calendly/schedule/:calendlyIntegId'} element={<CalendlySchedulePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
