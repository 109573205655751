import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Stripe from 'stripe';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import './PaywallPage.scss';
import PaywallAsset from '../../../assets/onboarding/paywall.png';
import {
  OnboardingContainer,
  SubscriptionPlanContent,
  SubscriptionPlanItem,
  SubscriptionTimeline,
} from '../../../components';
import { FREE_TRIAL_DAYS, useAuthContext, useAvailableSubscriptions } from '../../../data-access';
import { translatePlanPriceDetail } from './helpers';
import { CardPayment } from './CardPayment';
import { useFeatureFlag } from '../../../utils';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const PaywallPage: FC = () => {
  const { availableSubscriptions } = useAvailableSubscriptions({ provider: 'stripe' }); // Web supports only Stripe payment.
  const [selectedPlan, setSelectedPlan] = useState<Stripe.Price | undefined>();
  const { signOut } = useAuthContext();
  const navigate = useNavigate();
  const {
    featureFlags: { FF_paywall_regular_price = false },
  } = useFeatureFlag();

  const handleGoback = () => {
    signOut();
    setTimeout(() => {
      navigate('/');
    }, 1000);
  };

  useEffect(() => {
    if (!availableSubscriptions.length || FF_paywall_regular_price) {
      return;
    }

    const annualSubscription = availableSubscriptions.find((s) => s.recurring?.interval === 'year');
    if (!annualSubscription) {
      return;
    }
    setSelectedPlan(annualSubscription);
  }, [availableSubscriptions]);

  return (
    <div className="paywall-page">
      <OnboardingContainer
        leftChildren={
          <img src={PaywallAsset} alt="lifestack paywall" className="paywall-page__asset" />
        }
        rightChildren={
          <div className="paywall-page__right-section">
            <div className="paywall-page__content">
              <div className="paywall-page__content__title">Start your free trial</div>

              {FF_paywall_regular_price ? (
                <>
                  <div className="paywall-page__content__plans">
                    {availableSubscriptions.map((plan) => {
                      const recurring = plan.recurring.interval;

                      return (
                        <SubscriptionPlanItem
                          key={plan.id}
                          title={recurring === 'month' ? 'Monthly plan' : 'Annual plan'}
                          isSelected={selectedPlan?.id === plan.id}
                          isBestDeal={recurring === 'year'}
                          description={translatePlanPriceDetail(plan)}
                          onClick={() => setSelectedPlan(plan)}
                        />
                      );
                    })}
                    <SubscriptionPlanContent className="paywall-page__content__plans__explanation" />
                  </div>
                  <div className="paywall-page__content__bottom-group">
                    <SubscriptionTimeline
                      startTrialDate={dayjs().format('YYYY-MM-DD')}
                      startChargeDate={dayjs().add(FREE_TRIAL_DAYS, 'day').format('YYYY-MM-DD')}
                      price={selectedPlan ? translatePlanPriceDetail(selectedPlan, true) : ''}
                    />
                    <Elements stripe={stripePromise}>
                      <CardPayment selectedPlan={selectedPlan} />
                    </Elements>
                  </div>
                </>
              ) : (
                <div className="paywall-page__content__description">
                  You need to subscribe to the mobile app first to continue using the web app.
                </div>
              )}
              <Button variant="text" onClick={handleGoback} className="paywall-page__go-back">
                Go Back
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default PaywallPage;
