import {
  Calendar,
  CalendarEvent,
  CalendlyIntegration,
  EnergyFluctuationItem,
  FluctuateReasonType,
  HeatmapDataType,
  PhaseStartEndSet,
  TaskItem,
  TodoTasksBoard,
  Token,
  User,
} from '@demind-inc/core';

export enum Status {
  LOADING = 'loading',
  UN_AUTHORIZED = 'unAuthorized',
  AUTHORIZED = 'authorized',
}

export type FirestoreUser = Omit<User, 'userId'>;
export type FirestoreTaskItem = Omit<TaskItem, 'taskId'>;
export type FirestoreCalendar = Omit<Calendar, 'calendarId'>;
export type FirestoreToken = Omit<Token, 'tokenId'>;
export type FirestoreCalendlyInteg = Omit<CalendlyIntegration, 'calendlyIntegrationId'>;

export type PeakDipPhase =
  | 'wakeup_low'
  | 'morning_high'
  | 'morning_low'
  | 'evening_high'
  | 'evening_low';

export type PeakDipStartEndSet = Pick<PhaseStartEndSet, PeakDipPhase>;

export type CalendarEventCreateOption = Partial<Omit<CalendarEvent, 'updatedDate' | 'createdDate'>>;
export type CalendarEventModifyOption = Partial<
  Omit<CalendarEvent, 'eventId' | 'updatedDate' | 'createdDate' | 'calendarId'>
>;

export type FluctuationSetByReasons = Partial<Record<FluctuateReasonType, EnergyFluctuationItem[]>>;

export interface TasksByDate {
  date: string;
  tasks: TaskItem[];
}

export interface CircadianRhythmByDate {
  date: string;
  values: HeatmapDataType[];
  fluctuations?: FluctuationSetByReasons;
}

export type EventDetailsMenuMode = 'details' | 'edit';

export type PaymentProvider = 'stripe' | 'revenuecat';

export type AddTodoTaskItemProps = Omit<TaskItem, 'taskId' | 'originalTaskId'>;
export type AddTodoTaskBoardProps = Omit<
  TodoTasksBoard,
  'todoTaskId' | 'originalBoardId' | 'createdAt'
>;
