import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import clsx from 'clsx';
import { TaskItem, TodoIntegrationType } from '@demind-inc/core';
import { useRecoilState } from 'recoil';
import { useDrop } from 'react-dnd';

import './KanbanList.scss';
import { KanbanTaskCard } from '../KanbanTaskCard';
import {
  useAuthContext,
  useCircadianContext,
  useTodoIntegrations,
  useTodoTasksContext,
  visibleTaskDetailAtom,
} from '../../../data-access';
import { TaskDetailModal } from '../../TaskDetailModal';
import { useDropdownState } from '../../../hooks';
import { ConnectTodosDropdown } from '../../TodoIntegration';
import { CircularProgress } from '@mui/material';
import { trackEventGA4 } from '../../../utils';
import { AddOutlined } from '@mui/icons-material';

interface KanbanListProps {
  date: Dayjs;
  tasks: TaskItem[];
  isFetchingTasks?: boolean;
  generatingTodoAuth?: TodoIntegrationType;
  onConnectTodo?: (todoType: TodoIntegrationType) => void;
  onMoveTask: (task: TaskItem) => void;
  className?: string;
  isOverDue?: boolean;
}

const KanbanList: React.FC<KanbanListProps> = ({
  date,
  tasks,
  isFetchingTasks = false,
  generatingTodoAuth,
  onConnectTodo = () => void 0,
  onMoveTask,
  className,
  isOverDue,
}) => {
  const { findProject, todoProjects } = useTodoTasksContext();
  const [visibleTask, setVisibleTask] = useRecoilState(visibleTaskDetailAtom);
  const { findPhaseForTaskTime } = useCircadianContext();
  const { anchorEl, handleCloseDropdown } = useDropdownState();
  const { user } = useAuthContext();
  const { todoIntegrations } = useTodoIntegrations({ userId: user?.userId });
  const hasNoProjects = !todoProjects.length;

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: 'TASK',
      drop: onMoveTask,
      canDrop: (item) => {
        if (isOverDue) return false;
        return (
          dayjs(item?.dueDateTime?.datetime).format('YYYY-MM-DD') !== date?.format('YYYY-MM-DD')
        );
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onMoveTask]
  );
  return (
    <div ref={drop} className={clsx('kanban-list', className)}>
      <div className="kanban-list__header">
        <div className="kanban-list__header__day">
          {isOverDue ? 'Overdue' : date.format('dddd')}
        </div>
        <div className="kanban-list__header__date">
          {isOverDue
            ? `- ${dayjs(date).subtract(1, 'day').format('MMM DD')}`
            : date.format('MMM DD')}
        </div>
      </div>
      <div className="kanban-list__item-group">
        {isFetchingTasks && <CircularProgress className="kanban-list__loading" size={30} />}
        {!isFetchingTasks &&
          !hasNoProjects &&
          tasks.map((task) => (
            <KanbanTaskCard
              key={task.taskId}
              task={task}
              phase={
                task.startDateTime?.datetime && task.dueDateTime?.datetime
                  ? findPhaseForTaskTime(task.startDateTime.datetime, task.dueDateTime.datetime)
                  : undefined
              }
              isOverDue={isOverDue}
              projectName={findProject(task.boardId!)?.name}
              onClick={() => {
                setVisibleTask(task);
                trackEventGA4('Modal_open', 'open_task_detail_modal', { taskId: task.taskId });
              }}
            />
          ))}
        {isOver && canDrop && <div className="kanban-list__preview-card" />}
        {!isFetchingTasks && (
          <button
            className="kanban-list__add-task"
            onClick={() =>
              setVisibleTask({
                dueDateTime: {
                  date: dayjs(date).format('YYYY-MM-DD'),
                  timezone: dayjs.tz.guess(),
                },
              } as TaskItem)
            }
          >
            <AddOutlined className="kanban-list__add-task__icon" />
            <p className="kanban-list__add-task__text">Add task</p>
          </button>
        )}
      </div>
      <TaskDetailModal
        task={visibleTask!}
        visible={!!visibleTask}
        onClose={() => setVisibleTask(null)}
      />
      <ConnectTodosDropdown
        generatingTodoAuth={generatingTodoAuth}
        anchorEl={anchorEl}
        onClose={handleCloseDropdown}
        onClickItem={onConnectTodo}
        connectedTodos={todoIntegrations}
      />
    </div>
  );
};

export default KanbanList;
