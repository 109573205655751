import { useAuthContext, useCalendarContext } from '../../../../data-access';
import { first } from 'lodash';
import { Button } from '@mui/material';

import './CalendarsSettings.scss';
import { getCssVariable, useFeatureFlag } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import { Path } from '../../../../routing';

const primaryColor = getCssVariable('--color-primary');

const CalendarsSettings = () => {
  const { calendarsMeta } = useCalendarContext();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const {
    featureFlags: { FF_calendly = false },
  } = useFeatureFlag();

  return (
    <div className="calendars-settings">
      <p className="calendars-settings__title">Calendar</p>
      <div className="calendars-settings__grid">
        {calendarsMeta?.map((calendar) => (
          <div key={calendar.calendarId} className="calendars-settings__calendar-item">
            <div
              className="calendars-settings__calendar-icon"
              style={{ background: calendar.color || primaryColor }}
            >
              {first(calendar.name)}
            </div>
            {calendar.rootEmail}
          </div>
        ))}
      </div>
      {(FF_calendly || user.access === 'internal') && (
        <Button
          className="calendars-settings__calendly"
          variant="outlined"
          onClick={() => navigate(Path.SETTINGS.CALENDLY)}
        >
          Calendly Integration
        </Button>
      )}
    </div>
  );
};

export default CalendarsSettings;
