import { FC, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import withDragAndDrop, {
  DragFromOutsideItemArgs,
  EventInteractionArgs,
} from 'react-big-calendar/lib/addons/dragAndDrop';
import { HeatmapDataType } from '@demind-inc/core';

import './CalendarTimeline.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useRecoilValue } from 'recoil';

import { EventBlock } from './EventBlock';
import { RBCEvent } from './types';
import { CustomTimeGutter } from './CustomTimeGutter';
import {
  PeakDipPhase,
  PeakDipStartEndSet,
  selectedDateAtom,
  useCreateEventMenuContext,
  useEventDetailsMenuContext,
} from '../../../data-access';
import { zoneColorForPeaksDipsPhase } from './constants';
import { getCssVariable } from '../../../utils';
import { CreateEventMenu, EditEventMenu, EventDetailsMenu } from '../EventMenu';
import { transformCreatingEventToRBCEvent } from './helpers';
import { useGeneralSettings } from '../../../hooks/useGeneralSettings';

interface CalendarTimelineProps {
  events: RBCEvent[];
  circadianRhythms: HeatmapDataType[];
  peaksDipsBoundaries?: PeakDipStartEndSet;
  hasNoSleepData?: boolean;
  onEventDrop: (data: EventInteractionArgs<object>) => void;
  onEventResize: (data: EventInteractionArgs<object>) => void;
  onDropFromOutside: (data: DragFromOutsideItemArgs) => void;
  className?: string;
}

const localizer = dayjsLocalizer(dayjs);
const DnDCalendar = withDragAndDrop(Calendar);
const primaryColor = getCssVariable('--color-primary');

const CalendarTimeline: FC<CalendarTimelineProps> = ({
  events,
  circadianRhythms,
  peaksDipsBoundaries,
  hasNoSleepData = false,
  onDropFromOutside,
  onEventDrop,
  onEventResize,
  className,
}) => {
  const selectedDate = useRecoilValue(selectedDateAtom);
  const { creatingEvent, handleSelectSlot, clearCreatingEvent } = useCreateEventMenuContext();
  const { visibleMenuMode, selectedEvent, handleSelectEvent, clearSelectedEvent } =
    useEventDetailsMenuContext();
  const {
    generalSettings: { timeFormat },
  } = useGeneralSettings();
  const selectedTimeFormat = timeFormat === '12h' ? 'h a' : 'HH';
  // Zone
  const backgroundEvents = useMemo(
    () =>
      peaksDipsBoundaries
        ? Object.entries(peaksDipsBoundaries).map(([phase, { start, end }]) => ({
            start: new Date(start),
            end: new Date(end),
            color: zoneColorForPeaksDipsPhase[phase as PeakDipPhase],
          }))
        : [],
    [peaksDipsBoundaries]
  );

  const eventWithCreatingOne = useMemo(
    () => (creatingEvent ? [...events, transformCreatingEventToRBCEvent(creatingEvent)] : events),
    [creatingEvent, events]
  );

  return (
    <>
      <DnDCalendar
        date={selectedDate.toDate()}
        defaultView="day"
        events={eventWithCreatingOne}
        localizer={localizer}
        onEventDrop={onEventDrop}
        onSelectEvent={(event) => handleSelectEvent(event as RBCEvent)}
        onEventResize={onEventResize}
        onDropFromOutside={onDropFromOutside}
        onSelectSlot={handleSelectSlot}
        resizable
        selectable
        showMultiDayTimes
        step={15}
        dayLayoutAlgorithm="no-overlap"
        scrollToTime={selectedDate.toDate()}
        formats={{
          timeGutterFormat: (date) => {
            if (dayjs(date).minute() === 0) {
              return dayjs(date).format(selectedTimeFormat);
            }
          },
        }}
        backgroundEvents={backgroundEvents}
        components={{
          timeGutterWrapper: (props: React.PropsWithChildren<{}>) => {
            return (
              <CustomTimeGutter
                peaksDipsBoundaries={peaksDipsBoundaries}
                heatmapData={circadianRhythms}
                isDefaultHeatmap={hasNoSleepData}
              >
                {props.children}
              </CustomTimeGutter>
            );
          },
          event: (event) => <EventBlock event={event.event} />,
        }}
        style={{ height: '100vh' }}
        toolbar={false}
        eventPropGetter={(event) => {
          const rbcEvent = event as RBCEvent;

          if (rbcEvent.eventId) {
            // Calendar Event
            const isCreatingEvent = rbcEvent.eventId === 'creating';
            return {
              style: {
                backgroundColor: isCreatingEvent ? 'white' : `${rbcEvent.color}33`,
                border: isCreatingEvent ? `solid 1px ${primaryColor}` : 'none',
                padding: 0,
              },
            };
          } else {
            // Zone
            return {
              style: {
                backgroundColor: `${rbcEvent.color}1A`,
                border: `1px solid ${rbcEvent.color}`,
                borderRadius: '0',
                borderLeft: 'none',
                borderRight: 'none',
              },
            };
          }
        }}
        className={clsx('calendar-timeline', className)}
      />
      <CreateEventMenu visible={!!creatingEvent} onClose={clearCreatingEvent} />
      <EventDetailsMenu
        visible={!!selectedEvent && visibleMenuMode === 'details'}
        onClose={clearSelectedEvent}
      />
      <EditEventMenu
        visible={!!selectedEvent && visibleMenuMode === 'edit'}
        onClose={clearSelectedEvent}
      />
    </>
  );
};

export default CalendarTimeline;
