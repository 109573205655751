import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import './OutlookCallback.scss';
import { calendarApi, useAuthContext, useSyncOutlookCalendarsList } from '../../../data-access';
import { Path } from '../../../routing';

const OutlookCallback: FC = () => {
  const location = useLocation();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { syncOutlookCalendarsList } = useSyncOutlookCalendarsList();

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code') ?? '';

  useEffect(() => {
    if (!user.userId || !code) {
      return;
    }

    const update = async () => {
      const tokens = await calendarApi.exchangeOutlookToken(
        user.userId,
        code,
        `${window.location.origin}/callback/outlook`
      );

      if (tokens) {
        const newCalendarIds = await syncOutlookCalendarsList({ userId: user.userId });
      }

      navigate(Path.DASHBOARD);
    };

    update();
  }, [user?.userId, code]);

  return (
    <div className="outlook-callback-page">
      <CircularProgress />
    </div>
  );
};

export default OutlookCallback;
