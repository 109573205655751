import { useMutation, useQueryClient } from '@tanstack/react-query';

import { tasksApi } from '../api';
import { AddTodoTaskItemProps } from '../types';

interface UseAddTodoTaskParams {
  boardId: string;
  userId: string;
  newTaskInfo: AddTodoTaskItemProps;
}

export const useAddTodoTask = () => {
  const queryClient = useQueryClient();

  const addTaskMutation = useMutation({
    mutationFn: ({ boardId, userId, newTaskInfo }: UseAddTodoTaskParams) => {
      return tasksApi.addTodoTask(boardId, userId, newTaskInfo).then(({ data }) => data.taskId);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['lifestack.todo.tasks'] });
    },
  });

  return {
    addTodoTask: addTaskMutation.mutateAsync,
    ...addTaskMutation,
  };
};
