import React from 'react';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import './WearableButton.scss';

interface WearableButtonProps {
  id: string;
  name: string;
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  isConnected: boolean;
  isOnlyMobileSdkSupport: boolean;
  isLoading: boolean;
  onClick: (id: string) => void;
}

const WearableButton: React.FC<WearableButtonProps> = ({
  id,
  name,
  Icon,
  isConnected,
  isOnlyMobileSdkSupport,
  isLoading,
  onClick,
}) => {
  return (
    <button
      onClick={() => onClick(id)}
      className={clsx(
        'wearable-button',
        isConnected ? 'wearable-button--connected' : 'wearable-button--disconnected'
      )}
      disabled={isConnected || isOnlyMobileSdkSupport || isLoading}
    >
      <div className="wearable-button__content">
        <Icon className="wearable-button__icon" />
        {name}
      </div>
      <span className="wearable-button__status">
        {isLoading ? <CircularProgress size={14} /> : isConnected ? 'Connected' : 'Connect'}
      </span>
    </button>
  );
};

export default WearableButton;
