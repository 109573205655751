import { useEffect, useState } from 'react';
import { remoteConfig } from '../data-access';
import { fetchAndActivate, getAll, Value } from 'firebase/remote-config';

//@link: https://www.notion.so/demind-inc/Feature-Flags-2327fe3c821a444d9076b771c606c0be

type RemoteConfigType = Record<string, string | number | boolean>;

export const defaultRemoteConfig: RemoteConfigType = {
  force_update_version: '1.0.0',
  FF_paywall_regular_price: false,
  FF_calendly: false,
};

export const useFeatureFlag = () => {
  const [featureFlags, setFeatureFlags] = useState<RemoteConfigType>(defaultRemoteConfig);

  useEffect(() => {
    const init = async () => {
      await fetchAndActivate(remoteConfig);
      remoteConfig.defaultConfig = defaultRemoteConfig;

      const ff = getAll(remoteConfig);

      setFeatureFlags({
        FF_calendly: ff.FF_calendly.asBoolean(),
        FF_paywall_regular_price: ff.FF_paywall_regular_price.asBoolean(),
        force_update_version: ff.force_update_version.asString(),
      });
    };
    init();
  }, []);

  return { featureFlags };
};
